label {
    margin: 0;
}

option {
    background-color: #282828 !important;
}

.swal2-container {
    z-index: 20000 !important;
}

.ant-switch,
.ant-switch:not(.ant-switch-checked) > .ant-switch-inner {
    background-color: #282828 !important;
}

.ant-switch:not(.ant-switch-checked) > .ant-switch-handle::before {
    background-color: #FFF !important;
}

.ant-picker-outlined {
    z-index: 1000000000 !important;
}

.ant-picker-focused {
    z-index: 1000000000 !important;
}

.ant-picker {
    z-index: 1000000000 !important;
}

input::placeholder {
    color: #FFF !important;
}

input.order-input {
    margin-top: 8px;
    font-size: 16px;
    background: transparent;
    color: #FFF !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: 1px solid #616161 !important;
    width: 100%;

}

#date-popup > div {
    position: relative !important;
}

.bg-default {
    background-color: #1d1d1d !important;
}

.main-tabs .nav-item {
    border: none;
}

.main-tabs .nav-item button {
    background: none !important;
    color: #000 !important;
    border-top: none !important;
    border-left: none !important;
}

.MuiTablePagination-selectLabel {
    height: 100% !important;
    margin-top: auto !important;
    color: #cccccc;
}

.MuiTablePagination-displayedRows {
    height: 100% !important;
    margin-top: auto !important;
    color: #cccccc;
}

.MuiToolbar-root {
    padding-left: 0 !important;
}

.info-hint p {
    color: #c2c2c2;
    font-family: Helixa;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
}

.btn-default-tab {
    background: #121212 !important;
    border: none !important;
    color: #ffffff !important;
}

.btn-primary-tab {
    background: #404040 !important;
    border: none !important;
    color: #ffffff !important;
}

.MuiTableCell-root, .MuiTypography-root {
    font-family: 'Open Sans' !important;
}
